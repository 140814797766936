export default {
  "skans-syxzj": [
    {
      fromDate: "2022-08-16T13:35:16-04:00",
      toDate: "2022-08-23T13:34:01-04:00",
      id: 1,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Excavation progressed, crane installed.",
        changes: [
          "Excavation and foundation work are underway.",
          "Retaining walls are in place, and heavy machinery is actively engaged in earthmoving and material preparation.",
          "A crane is set up, signaling readiness for vertical construction.",
        ],
      },
    },
    {
      fromDate: "2022-09-30T13:05:30-04:00",
      toDate: "2022-10-10T12:04:55-04:00",
      id: 2,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Foundation laid, vertical construction started.",
        changes: [
          "Excavation completed.",
          "Foundation in progress.",
          "Concrete slabs poured in sections.",
          "Rebar installation underway.",
          "Vertical construction elements erected.",
          "Heavy machinery active for material handling.",
          "Crane in use for structural steelwork.",
        ],
      },
    },
    {
      fromDate: "2022-10-18T12:35:57-04:00",
      toDate: "2022-10-25T13:59:23-04:00",
      id: 3,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Steel framework erected, more floors visible.",
        changes: [
          "Foundation phase near completion.",
          "Structural steelwork advancing with columns and beams in place.",
          "Formwork for new concrete sections prepared.",
          "Excavation for utilities ongoing.",
          "Solar panels installed on temporary structures.",
          "Material staging areas replenished.",
        ],
      },
    },
    {
      fromDate: "2022-11-01T12:03:27-04:00",
      toDate: "2022-11-02T12:02:18-04:00",
      id: 4,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Subfloor installation underway.",
        changes: [
          "Foundation work completed.",
          "Structural steelwork extensively in place with multi-level framework visible.",
          "Concrete curing on newly poured sections.",
          "Rebar setup continues for upcoming concrete work.",
          "Heavy machinery active for earthwork and materials transport.",
          "Steel decking installation in progress for flooring.",
        ],
      },
    },
    {
      fromDate: "2022-11-07T12:02:00-05:00",
      toDate: "2022-11-09T12:04:21-05:00",
      id: 5,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Floor slab formwork increased.",
        changes: [
          "Steel framework construction continues with additional beams and columns installed.",
          "Decking for floors expanded.",
          "Concrete formwork in preparation for new pours.",
          "Rebar being laid for upcoming concrete work.",
          "Active machinery for material handling and site preparation.",
        ],
      },
    },
    {
      fromDate: "2022-12-02T12:19:59-05:00",
      toDate: "2022-12-07T12:13:46-05:00",
      id: 6,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Steel structure erected; foundation phase to skeleton phase.",
        changes: [
          "Steel framework further constructed with new levels added.",
          "Floor decking near complete on lower levels.",
          "Formwork for concrete staircases visible.",
          "Continued rebar placement for concrete pours.",
          "Site active with machinery for excavation and material handling.",
        ],
      },
    },
    {
      fromDate: "2022-12-14T12:05:40-05:00",
      toDate: "2022-12-21T13:58:47-05:00",
      id: 7,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Progress on decking and column installation visible.",
        changes: [
          "Steel framework construction continues with additional beams and columns installed.",
          "Floor decking near complete on lower levels.",
          "Formwork for concrete staircases visible.",
          "Continued rebar placement for concrete pours.",
          "Site active with machinery for excavation and material handling.",
        ],
      },
    },
    {
      fromDate: "2022-12-29T12:04:13-05:00",
      toDate: "2023-01-02T12:34:01-05:00",
      id: 8,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Concrete floor slabs installation advanced.",
        changes: [
          "Steel superstructure further erected with additional floor levels.",
          "Floor decking installation continues.",
          "Concrete work progresses with staircases and elevated slabs.",
          "Rebar placement for future concrete pours evident.",
          "Heavy machinery operational for earth moving and materials transport.",
        ],
      },
    },
    {
      fromDate: "2023-01-12T12:59:43-05:00",
      toDate: "2023-01-16T12:59:53-05:00",
      id: 9,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Rebar installation for concrete slabs initiated.",
        changes: [
          "Steel superstructure completion on multiple levels.",
          "Floor decking substantially complete across lower levels.",
          "Concrete pours finalized on several floors.",
          "Rebar and formwork setup for additional concrete work.",
          "Heavy machinery present for ongoing site development and material transport.",
        ],
      },
    },
    {
      fromDate: "2023-01-23T13:01:31-05:00",
      toDate: "2023-01-25T13:04:21-05:00",
      id: 10,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Concrete pouring on slabs has commenced.",
        changes: [
          "Superstructure has advanced with more steel beams and columns in place.",
          "Concrete flooring has been poured on several levels.",
          "Work on stairwells and elevator shafts progresses.",
          "Rebar for upcoming concrete pours prepared.",
          "Machinery is active on-site for material distribution and structural work.",
        ],
      },
    },
    {
      fromDate: "2023-02-21T12:24:56-05:00",
      toDate: "2023-02-23T12:12:52-05:00",
      id: 11,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Formwork for new section. Increased floor slab area.",
        changes: [
          "Steel erection has advanced with additional floors framed.",
          "Concrete work on floors and staircases continues.",
          "Rebar and formwork prepared for further pours.",
          "Site active with heavy machinery for excavation and lifting operations.",
        ],
      },
    },
    {
      fromDate: "2023-03-03T13:50:11-05:00",
      toDate: "2023-03-07T13:55:40-05:00",
      id: 12,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Upper-level floor construction begun.",
        changes: [
          "Upper-level steel framework construction continues.",
          "Concrete floor slabs cured on multiple levels.",
          "Formwork in place for additional concrete work.",
          "Rebar arranged for imminent pours.",
          "Active site with machinery handling earth and construction materials.",
        ],
      },
    },
    {
      fromDate: "2023-03-13T13:47:35-04:00",
      toDate: "2023-03-16T12:08:10-04:00",
      id: 13,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Steel frame progress; more floors outlined.",
        changes: [
          "Superstructure with steel beams and columns installed across additional levels.",
          "Concrete slabs present on several floors.",
          "Formwork and rebar in place for further concrete work.",
          "Heavy machinery operational for construction activities.",
        ],
      },
    },
    {
      fromDate: "2023-03-16T12:08:10-04:00",
      toDate: "2023-03-20T12:12:20-04:00",
      id: 14,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title:
          "Structural steelwork expaded, formwork for concrete progressing.",
        changes: [
          "Steel superstructure construction has progressed with additional floor levels.",
          "Concrete floor slabs have been poured and are visible across several levels.",
          "Formwork is underway for further concrete work, with rebar installation in preparation for upcoming pours.",
          "The site shows active machinery and equipment for material handling and construction progression.",
        ],
      },
    },
    {
      fromDate: "2023-05-30T12:32:22-04:00",
      toDate: "2023-05-31T12:27:52-04:00",
      id: 15,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Substructure completion, superstructure development.",
        changes: [
          "Upper floors' steel framework is nearing completion.",
          "Concrete floor slabs are fully poured on several levels, with some floors now accessible.",
          "Exterior wall installations have begun in certain sections.",
          "Site activity includes machinery for lifting and distributing construction materials.",
        ],
      },
    },
    {
      fromDate: "2023-06-20T13:21:36-04:00",
      toDate: "2023-06-22T13:11:56-04:00",
      id: 16,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Superstructure development, pre-enclosure stage.",
        changes: [
          "Exterior wall panel installation is underway.",
          "Steel superstructure appears complete.",
          "Interior floors are largely in place with concrete pours apparent.",
          "Work on internal stairwells and elevator shafts is progressing.",
          "Construction activity is concentrated on the building's interior, with scaffolding and staging areas set up.",
        ],
      },
    },
    {
      fromDate: "2023-12-11T13:22:24-05:00",
      toDate: "2023-12-13T13:14:44-05:00",
      id: 17,
      cameraExid: "skask-oakxp",
      projectExid: "skans-syxzj",
      response: {
        title: "Enclosure and interior work phase.",
        changes: [
          "Exterior paneling on the building is nearly complete, with scaffolding still present.",
          "The steel structure is fully erected, with floor slabs poured and set on all levels.",
          "Interior work is progressing, as indicated by the presence of construction materials and equipment.",
          "The large crane remains on site, suggesting ongoing heavy lifting operations.",
        ],
      },
    },
  ],
}
